import './scss/components/_iko-navigation-top-bar.scss';

document.addEventListener('DOMContentLoaded', e => {
  let prevScrollpos = window.pageYOffset;
  const navBarTop = document.querySelector('.iko-navigation-top-bar');
  const navBarMain = document.querySelector('.iko-navigation__main');

  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      navBarTop.classList.remove('iko-navigation-top-bar--collapsed');
      if (navBarMain.classList.contains('iko-navigation-home')) {
        navBarMain.classList.add('iko-navigation__main--secondary--yellow');
      }
    } else {
      navBarTop.classList.add('iko-navigation-top-bar--collapsed');
      if (navBarMain.classList.contains('iko-navigation-home')) {
        navBarMain.classList.remove('iko-navigation__main--secondary--yellow');
      }
    }
    prevScrollpos = currentScrollPos;
  }
});